.content {
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 24px;
    display: grid;
    grid-template-columns: 53% 22%;
    justify-content: center;
    align-items: center;
    padding: 45px 0px;
    grid-column-gap: 5%;
}

.content svg {
    margin-left: auto;
}

/*.content .info h3 {*/
/*    margin-bottom: 6px;*/
/*}*/

.content .info p {
    margin: 0 0 6px 0;
}
@media (max-width: 767px) {
    .content {
        grid-template-columns: 100%;
        padding: 25px 12px;
    }
    .content svg{
        margin: 0 auto 15px auto;
        order: -1;
        height: 125px;
    }

}
