body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.link-register {
    display: flex;
}
.link-register svg{
    max-height: 35px;
    max-width: 35px;
}
.link-register:hover svg  *{
    fill: blue;
}

.icon-trash:hover path{
    stroke: var(--red);
}


.icon-arrow.icon-arrow-open {
    transform: rotate(180deg) !important;
}