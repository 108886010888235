.section_calculate_instantly {
    margin-top: 86px;
    margin-bottom: 64px;
}
.section_calculate_instantly .content{
    background: #F3F4F8;
    border-radius: 24px;
    padding: 48px;
}
.section_calculate_instantly .content h3{
    /*margin: 0px 0px 4px 0px;*/
}
.section_calculate_instantly .content p{
    margin: 0px 0px 6px 0px;
}
.section_calculate_instantly .content img{
    display: block;
    margin: 0px -15px 0px -15px;
}
@media (max-width: 992px) {
    .section_calculate_instantly {
        margin-top: 48px;
        margin-bottom: 34px;
    }

}
@media (max-width: 767px) {
    .section_calculate_instantly .content{
        padding: 22px 15px;
        border-radius: 12px;
    }
    .section_calculate_instantly .content img{
        margin: 0px;
    }
}