.choice-type-login {
    margin-bottom: 25px;
    justify-content: center;
    gap: 8px;
}
.choice-type-login input[type="radio"] + label{
    font-size: 16px;
}
.form-sm {
    max-width: 100%;
    width: 420px;
    margin: 0 auto;
    box-sizing: border-box;
}

.card-form {
    padding: 15px 15px;
    border: 1px solid var(--border);
    border-radius: 8px;
    /*box-shadow: 0 0 5px var(--grey);*/
}

section.section-login-platform {
    margin: 0 0 120px 0;
}

.section-login-platform .tab-root {
    margin-bottom: 45px;
}

.section-login-platform .tab-root .tab-section {
    justify-content: center;
    flex-wrap: wrap;

}

.section-login-platform .tab-root {
    background-color: var(--grey);
    gap: 8px
}

.section-login-platform .tab-root .tab {
    cursor: pointer;
    padding: 12px 8px;
    border-bottom: 3px solid #cdccd7;
    font-size: 14px;
    font-weight: 400;
}

.section-login-platform .tab-root .tab.active {
    border-color: blue;
    color: var(--blue);
}

.form-title {
    text-align: center;
    font-weight: 500;

}

.form-sub-title {
    font-size: 20px;
    text-align: center;
    font-weight: 400;
}

.switch-form-tab {
    border-top: 1px solid var(--grey);
    margin-top: 20px;
    padding: 20px 0 0;
}

.forgot-password {
    font-size: 14px;
    color: var(--blue);
}

@media (min-width: 992px) {
    .section-login-platform .tab-root .tab {
        font-size: 18px;
    }
}

.section-connect-firms-platform {
    margin: 55px 0 120px;
}

.section-connect-firms-platform ul {
    margin-top: 35px;
    gap: 12px;
}

.choice-firm-register ul {
    max-height: 350px;
    overflow-y: auto;
}

.step-register-bot .content {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.custom-btn-prev {
    display: flex;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

span.custom-btn-prev .icon-arrow {
    transform: rotate(90deg);
}

.register-page-header {
    margin-bottom: 20px;
}


/*MESSAGE CHANNEL*/
.message-channel-entry {
    flex-wrap: wrap;
    gap: 10px;
}

.un-connected-channel-entry .item {
    position: relative;
    z-index: 1;
}

.un-connected-channel-entry .item img {
    display: none;
    width: 550px;
    max-width: min-content;
    position: absolute;
    transform: translateY(-100%);
}

.un-connected-channel-entry .item:hover img {
    display: block;
}

.resend-password,
.resend-action {
    font-size: 14px;
    text-decoration: underline;
    color: var(--orange);
    display: block;
    margin: 12px 0;
    cursor: pointer;
}

.form-group + .resend-password {
    margin-top: -15px;
}

.resend-password.disabled {
    color: var(--gray-violet);
}

.connected-channel .item {
    display: flex;
    cursor: pointer;
    padding: 8px;
    border: 1px solid var(--gray-violet);
    justify-content: center;
    border-radius: 6px;
}

.connected-channel .item.active,
.connected-channel .item.default {
    border: 1px solid var(--blue);
}

.connected-channel .item:hover {
    box-shadow: 0 0 4px #c2c2c2;
}

.bot-info {
    position: relative;

}

.bot-info .info + img {
    box-shadow: 0 0 5px #ccc;
    display: none;
    max-width: 100%;
    width: 450px;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
}

.bot-info .info {
    cursor: pointer;
    margin-left: 8px;
}

.bot-info .info:hover + img {
    display: block;
    z-index: 5;

}

.seconds{
    color: #2a8000;
    font-size: 15px;
    font-weight: 600;
}