
.filed-phone-mask {
    display: grid;
    border: 1px solid var(--border);
    border-radius: 8px;
    grid-template-columns: 120px 1fr;
    overflow: hidden;
}

.filed-phone-mask select {
    padding: 0 0 0 0;
    border-radius: 0;
    height: 100%;
    font-size: 14px;
    text-align: center;
    width: 100%;
    border-right: 1px solid var(--border);
}


