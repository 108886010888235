.section_calculate_instantly{
    padding-top: 65px;
    border-radius: 24px;
    position: relative;
    margin: 65px 0px;
}
.section_calculate_instantly .circle {
    content: '';
    display: block;
    top:0;
    left: 0;
    right: 0;
    bottom: 35%;
    max-height: 100%;
    width: 100%;
    z-index: 2;
    position: absolute;
}
.section_calculate_instantly:before {

}

.section_calculate_instantly .content p{
    margin: 0 0 6px 0;
}
.section_calculate_instantly .content h2{
    font-weight: 600;
}
.section_calculate_instantly .content > *,
.section_calculate_instantly .content > img{
    position: relative;
    z-index: 3;
    color: white;
    text-align: center;
}

@media (max-width: 767px) {
    .section_calculate_instantly{
        padding-top: 20px;
        margin: 34px 0px;
    }
    .section_calculate_instantly:before{
        content: '';
        display: block;
        background: linear-gradient(0deg, #4262FF, #4262FF), #FFF1C0;
        border-radius: 16px ;
        position: absolute;
        top:0;
        bottom: 40%;
        left: 0;
        right: 0;
    }
    .section_calculate_instantly .circle{
        display: none;
    }
    .section_calculate_instantly .content *{
        text-align: left;
    }
    .section_calculate_instantly .content > img{
        margin-top: 24px;
    }
}