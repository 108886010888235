.section_step_connect{
    margin: 85px 0px;
}
.steps {
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3 - 16px));
    grid-gap: 24px;
}

.steps .item:nth-child(1) {
    grid-row: 1/3;
}

.item {
    background: #4262FF;
    border-radius: 16px;
    padding: 24px;
}

.item {
    display: flex;
    flex-direction: column;
}
.item svg{
    margin-left: auto;
    margin-top: 15px;
}
.item span{
    color: white;
    font-style: normal;
    font-weight: 200;
    margin-bottom: 12px;
}
.item p {
    color: white;
    margin: 0px;
    /*margin: auto 0;*/
}

@media (max-width: 767px) {
    .section_step_connect h3{
        margin-bottom: 24px;
    }
    .section_step_connect{
        margin: 34px 0px;
    }
    .steps {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 10px;
    }

    .steps .item:nth-child(1) svg{
       display: none;
    }
    .item {
        justify-content: center;
        align-items: center;
    }
    .item p{
        text-align: center;
    }
}