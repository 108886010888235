.st0{fill:none;stroke:#000000;}
.st1{fill:#00DCF9;stroke:#000000;}
.st2{fill:#FFFFFF;stroke:#FF0000;}
.st3{fill:none;stroke:#FF0000;stroke-miterlimit:10;}
.st4{stroke:#000000;}
.st5{fill:none;stroke:#FF0808;stroke-miterlimit:10;}
header  .logo svg{
    width: 100%;
    height: 100%;
}
.new-logo-st0{fill:#1D1D1B;}
.new-logo-st1{display:none;}