.section_online_service {
    background: linear-gradient(180deg, #F3F4F8 0%, rgba(243, 244, 248, 0) 100%);
    border-radius: 24px;
}

.section_online_service .header {
    padding: 55px 15px 25px 15px;
    display: grid;
    gap: 20px;
    justify-content: center;
}
.section_online_service .header img{
 border-radius: 6px;
    margin: 0 auto;
    max-height: 350px;
    display: block;
}
@media (max-width: 767px) {
    .section_online_service .header {
        padding: 40px 0 0 0;
    }

}

@media (min-width: 660px) {
    .section_online_service .header {
        justify-content: flex-start;
        display: grid;
        grid-template-columns: 1fr 30%;
        grid-column-gap: 35px;
    }
}