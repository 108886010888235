.section-tel-bot .content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 35px;
}

.section-tel-bot {
    margin: 55px 0;
}

.section-tel-bot .title {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
}

.bot-info  .title{
    margin-bottom: 0px;

}
.bot-info {
    position: relative;
    margin-bottom: 20px;
}

.bot-info .info + img {
    box-shadow: 0 0 5px #ccc;
    display: none;
    max-width: 100%;
    width: 450px;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
}
.bot-info .info{
    cursor: pointer;
    margin-left: 8px;
}
.bot-info .info:hover + img {
    display: block;

}
